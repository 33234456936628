import React from 'react';
import { Link } from 'gatsby';
import Layout from '../layout';
import './coming-soon.scss';

const ComingSoonPage = () => (
  <Layout className="coming-soon">
    <div className="coming-soon-title">
      <img src="/images/MLReef_loading.gif" alt="mlreef loading" />
    </div>
    <div className="coming-soon-content">
      <h2>You found a site that is filling up with content.</h2>
      <div className="">You can wait here, but maybe you will better off at home!</div>
    </div>
    <div className="coming-soon-actions">
      <Link to="/" className="btn btn-primary">
        Return home
      </Link>
    </div>
  </Layout>
);

export default ComingSoonPage;
